import { components, Observable, observable, PureComputed, pureComputed } from 'knockout';
import { BaseComponentViewModel, ComponentDependencies, OrderLine } from '@sidekix/flow';

export interface CartElementClassToggleViewModelParams extends components.ViewModelParams {
  context?: any;
  elementId?: string;
  className?: string;
}

export class CartElementClassToggleViewModel extends BaseComponentViewModel {
  public readonly context;
  public readonly elementId?: string;
  public readonly className: string = 'open';
  public readonly orderItems$: Observable<{ [key: string]: OrderLine }> = observable({});
  public readonly orderItemsQuantity$: PureComputed<number>;

  constructor(deps: ComponentDependencies, params?: CartElementClassToggleViewModelParams) {
    super(deps);

    this.context = params?.context ?? undefined;
    this.elementId = params?.elementId ?? this.elementId;
    this.className = params?.className ?? this.className;

    this.bindObservableToStore(this.orderItems$, '$.order.items');

    this.orderItemsQuantity$ = pureComputed(() => {
      let quantity = 0;

      const orderItems = this.orderItems$();

      for (const sku in orderItems) {
        quantity += orderItems[sku].quantity;
      }

      return quantity;
    });

    this.subscriptions.push(
      this.orderItemsQuantity$.subscribe(quantity => {
        if (quantity > 0) {
          this.toggle(true);
        }
      })
    );

    setTimeout(() => {
      // Automatically open the popup on page load if there is anything in the order.
      // This is pushed onto the event loop (setTimeout) so that knockout can first
      // render the element before the toggle is performed.
      // Otherwise, the dom element might not exist yet.
      if (this.orderItemsQuantity$() > 0) {
        this.toggle(true);
      }
    }, 0);
  }

  public toggle = (value?: boolean): void => {
    /* eslint-disable no-invalid-this */
    if (this.elementId) {
      const element = document.getElementById(this.elementId);

      if (element) {
        element.classList.toggle(this.className, value);
      }
    }
  };
}
