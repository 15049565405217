import template from './ProductBlock.html';
import {ComponentConfig} from '@sidekix/flow';
import {ProductBlockViewModel} from './ProductBlockViewModel';

export const productBlockComponent: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new ProductBlockViewModel(deps, params)
  }
};
