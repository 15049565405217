import template from './template.pug';
import {ComponentConfig} from '@sidekix/flow';
import {CartElementClassToggleViewModel} from './CartElementClassToggleViewModel';

export const cartElementClassToggleComponent: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new CartElementClassToggleViewModel(deps, params)
  }
};
