import {productBlockComponent} from './product-block';
import {cartElementClassToggleComponent} from './cart-element-class-toggle';
import {cartEmptyComponent} from './cart-empty';

const customComponents = {
  'product-block': productBlockComponent,
  'cart-element-class-toggle': cartElementClassToggleComponent,
  'cart-empty': cartEmptyComponent
};

export default customComponents;
