import { App, BaseComponentViewModel, ComponentDependencies, OrderLine, Product } from '@sidekix/flow';
import { components, Observable, observable, pureComputed, PureComputed } from 'knockout';

export interface ProductBlockViewModelParams extends components.ViewModelParams {
  sku?: string;
  variantSkus?: string[];
  texts?: {
    addToCart?: string;
    removeFromCart?: string;
    variantLabel?: string;
  }
}

export class ProductBlockViewModel extends BaseComponentViewModel {
  public mainProduct: Product;
  public mainSku: string;
  public skus: string[];
  public selectedVariant$: Observable<Product>;
  public selectedProduct$: Observable<Product>;
  public readonly orderIsPending$: Observable<boolean> = observable(false);
  public chosen$: PureComputed<boolean>;

  public readonly texts: {
    addToCart: string;
    removeFromCart: string;
    variantLabel: string;
  };

  private cart: App['cartService'];

  public constructor(deps: ComponentDependencies, params?: ProductBlockViewModelParams) {
    super(deps);
    this.cart = deps.cart;

    this.texts = {
      addToCart: params?.texts?.addToCart ?? 'In winkelmandje',
      removeFromCart: params?.texts?.removeFromCart ?? 'Verwijderen',
      variantLabel: params?.texts?.variantLabel ?? 'Kies uw maat'
    };

    this.mainSku = params!.sku!;
    this.skus = [this.mainSku, ...params!.variantSkus!];

    const products = this.store.getState().products;

    this.mainProduct = products[this.mainSku];

    this.selectedVariant$ = observable(products[this.skus[0]]);
    this.selectedProduct$ = this.skus.length > 1
      ? this.selectedVariant$
      : observable(this.mainProduct);

    const orderItems$ = observable<Record<string, OrderLine>>();
    this.bindObservableToStore(orderItems$, `$.order.items`);

    this.chosen$ = pureComputed(() => {
      const skusInOrder = Object.keys(orderItems$()!);
      return skusInOrder.some(skuInOrder => this.skus.includes(skuInOrder));
    });

    this.bindObservableToStore(this.orderIsPending$, '$.order._queues.orderItems.hasPendingItems');
  }
}
