import { BaseComponentViewModel, ComponentDependencies, OrderLine } from '@sidekix/flow';
import { components, observable, Observable, pureComputed, PureComputed } from 'knockout';

export interface CartEmptyViewModelParams extends components.ViewModelParams {
  text?: string
}

export class CartEmptyViewModel extends BaseComponentViewModel {
  public readonly text: string;
  public readonly orderItemsCount$: PureComputed<boolean>;
  public readonly orderItems$: Observable<Record<string, OrderLine>> = observable({});

  public constructor(deps: ComponentDependencies, params?: CartEmptyViewModelParams) {
    super(deps);

    this.text = params?.text ?? 'Het winkelmandje is leeg.';

    this.bindObservableToStore(this.orderItems$, '$.order.items');

    this.orderItemsCount$ = pureComputed(() => Object.keys(this.orderItems$()).length > 0);
  }
}
