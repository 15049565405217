import './assets/stylesheets/style.scss';

import customComponentList from './ui_components';
import { IzziService, LoginApp } from '@sidekix/flow';

(async () => {
  try {
    const mode = document.documentElement.dataset?.mode ?? 'production';
    const isProductionMode = !['development', 'test'].includes(mode);

    const izziServiceOptions = {
      loginUrl: undefined,
      loginConfigDataSource: isProductionMode ? undefined : './models/login.json',
      configDataSource: isProductionMode ? undefined : './models/registration.json',
      preSubmitModifier: undefined,
      campaignSettings: {
      }
    };

    const loginAppConfig = {
      bindingElem: document.body,
      componentDictionary: customComponentList,
      stages: [],
      translations: {
        nl: {
          translation: {
            /* Insert theme specific translation overrides here */
          }
        }
      }
    };

    const storeBackend = new IzziService(izziServiceOptions);
    const app = new LoginApp(loginAppConfig, storeBackend);

    await app.start();
  } catch (err) {
    console.error(err);
  }
})();
