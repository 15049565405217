import template from './CartEmpty.html';
import {ComponentConfig} from '@sidekix/flow';
import {CartEmptyViewModel} from './CartEmptyViewModel';

export const cartEmptyComponent: ComponentConfig = {
  template,
  viewModel: {
    createViewModel: (deps, params) => new CartEmptyViewModel(deps, params)
  }
};
